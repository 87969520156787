$desktop: 1024px;

.nav-container {
    @media (max-width: #{$desktop}) {
        display: flex;
        justify-content: space-between;
        z-index: 50;
        background-color: white;

        align-items: center;
        width: 100vw;
        height: 10vh;
        position: fixed;
        font-family: Kollektif;

    }

    @media (min-width: #{$desktop}) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        height: 5vh;
        position: fixed;
        font-family: Kollektif;
        font-size: 1vw;
        z-index: 1;
        background-color: white;
        overflow: hidden;
    }
}

.nav-left {
    @media (max-width: #{$desktop}) {
        margin-left: 2vw;


    }

    @media (min-width: #{$desktop}) {
        margin-left: 2vw;
    }
}

.nav-right {
    @media (max-width: #{$desktop}) {
        margin-right: 2vw;
        width: 100%;
        display: flex;
        justify-content: end;
    }

    @media (min-width: #{$desktop}) {
        padding-right: 2vw;
    }
}

.nav-right .nav-link {
    @media (max-width: #{$desktop}) {
        margin-right: 2vw;
    }

    @media (min-width: #{$desktop}) {
        padding-right: 2vw;
    }
}
