.work-title {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Gerbil;
    font-size: 15vw;

}

.color-overlay{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: white;
}


.spline-vo2 {
}

.spline-counselab {


}

.work3D{
    height: 100vh;
    opacity: .4;

}

#vo2{
    //background-color: #4954ff;
}

#counselab{
    //background-color: #18d4d8;
}
body html{
}
.work-text{
    position: absolute;
    z-index: 3;
    font-family: Gerbil;
    font-size: 10vw;
    mix-blend-mode: screen;
    color: white;
}
.work-scene{
    display: flex;
    align-items: center;
    justify-content: center;
}