$desktop: 1024px;

@font-face {
    font-family: 'Kollektif';
    src: url(../../fonts/Kollektif.ttf) format('truetype');
}

@font-face {
    font-family: 'Kollektif-bold';
    src: url(../../fonts/Kollektif-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Kollektif-italic';
    src: url(../../fonts/Kollektif-Italic.ttf) format('truetype');
}

@font-face {
    font-family: 'Kollektif-bolditalic';
    src: url(../../fonts/Kollektif-BoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Gerbil';
    src: url(../../fonts/gerbil.otf) format('opentype');
}

body{
}

//Loader
.loader {
    background: #000;
    width: 100vw;
    height: 400vh;
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.loader-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.ghost-gif {
    height: 20vh;

}

.home-main {
    @media (max-width: #{$desktop}) {
        padding: 4vw;

    }

    @media (min-width: #{$desktop}) {
        padding-left: 2vw;
        padding-right: 2vw;
        padding-top: 2vh;
        position: sticky;
        width: 90vw;
        top: 0;

    }
}

.home-container {
    @media (max-width: #{$desktop}) {
        margin-top: 10%;
        overflow: hidden;
        height: 88vh;
    }

    @media (min-width: #{$desktop}) {
        position: sticky;
        margin-bottom: 20vh;
        top: 0;
        
    }
}

.home-text {
    @media (max-width: #{$desktop}) {
        position: relative;
        top: 0;
        z-index: 1;
        pointer-events: none;
    }

    @media (min-width: #{$desktop}) {
        position: relative;
        top: 0;
        z-index: 1;
        pointer-events: none;
        
    }
}

.ghost-container {

    @media (max-width: #{$desktop}) {
        height: 100vh;
        width: 96vw;
        top: 0;
        z-index: 0;
    }

    @media (min-width: #{$desktop}) {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0%;
        z-index: 0;
        overflow: hidden;
    }
}

.ghost {
    @media (max-width: #{$desktop}) {
        top: -30%;
    }

    @media (min-width: #{$desktop}) {
        position: absolute;
        top: 0;
        left: 30vw;
    }
}

.hitbox {

    @media (max-width: #{$desktop}) {
        position: absolute;
        overflow: hidden;
        top: 30%;
        left: -2vw;
    }

    @media (min-width: #{$desktop}) {
        position: absolute;
        overflow: hidden;
        top: 30%;
        left: -2vw;

    }

}

.name {
    @media (max-width: #{$desktop}) {
        font-size: 15vw;
        font-family: Kollektif;

    }

    @media (min-width: #{$desktop}) {
        font-size: 8vw;
        font-family: Kollektif;

    }
}

.title {
    @media (max-width: #{$desktop}) {
        margin-top: 10%;
        font-family: Kollektif;
        font-size: 10vw;
    }

    @media (min-width: #{$desktop}) {
        margin-top: 2%;
        font-family: Kollektif;
        font-size: 3vw;
        pointer-events: none;
        display: flex;
        flex-direction: column;
    }
}

.title span,
.title .title-span-unique {
    @media (max-width: #{$desktop}) {
        font-family: Kollektif-bold;
        font-size: 10vw;
    }

    @media (min-width: #{$desktop}) {
        font-family: Kollektif-bold;
        font-size: 4vw;
    }
}

.title-2 {
    display: inline;
}



.title-span-unique {
    @media (max-width: #{$desktop}) {
        pointer-events: visible;
        cursor: grab;
        display: inline-block;
    }

    @media (min-width: #{$desktop}) {
        pointer-events: visible;
        cursor: grab;
        display: inline-block;

    }
}

.unique-container {
    display: inline;
}

.comp {
    position: absolute;
    width: 96vw;
}